import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../reducers/userReducer';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { Helmet } from 'react-helmet';
import Tracking from '../services/TrackingService';
import { setIsLoading, relogUser } from '../reducers/userReducer';
import { Link } from 'react-router-dom';

import { ReactComponent as Logo } from '../assets/logo.svg';
import { ReactComponent as InfoIcon } from "../assets/InfoIcon.svg";
import { ReactComponent as GoogleIcon } from '../assets/GoogleIcon.svg';
import { ReactComponent as DiscordIcon } from '../assets/DiscordIcon.svg';
import environment from '../helpers/envProvider';
import { registerUserToken } from '../firebase';
import { usePopper } from 'react-popper';

// ${TempVar_API}
// const TempVar_API = 'https://auth.logodiffusion.com' || 'https://auth-api-lodi.herokuapp.com';
const MAINTANANCE_MODE = environment('REACT_APP_MAINTANANCE') == 'true'
const MAINTANANCE_MESSAGE = environment('REACT_APP_MAINTANANCE_MESSAGE')

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  
  const isLoading = useSelector((state) => state.userSlice.isLoading);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

	const [view, setView] = useState('main');

	const [lastMethod, _setLastMethod] = useState(() => {
		return localStorage.getItem('lastLoginMethod')
	})

	const setLastMethod = (val) => {
		_setLastMethod(val)
		localStorage.setItem('lastLoginMethod', val)
	}

  const handleLoginUser = async (e) => {
    e.preventDefault();

    if(!email) return toast('Email is required.', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000
      });

    if(!password) return toast('Password is required.', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000
      });

    try {
      const userData = await dispatch(loginUser({ email, password })).unwrap();
			await registerUserToken(dispatch);
			setLastMethod('email')
      Tracking.send('login',{
        user: userData,
        token: userData.refreshToken
      },{
        // custom data
			  })
      navigate('/?loggedIn=true');
    } catch (error) {
      toast(error.response?.data?.detail || error.response?.data || 'Something went wrong, please try again.', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'dark',
        autoClose: 2000
      });

      dispatch(setIsLoading(false));

      console.log('error: ', error);
    }
  }

  useEffect(() => {
    const userId = searchParams.get('userId');
    const refreshToken = searchParams.get('refreshToken');
    const error = searchParams.get('error');

    if(error) {
      if(error === 'userNotFound') {
        toast('User doesn\'t exist. Please register and try again.', {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'dark',
          autoClose: 2000
        });
			} else if(error === 'noDiscordEmail') {
        toast("Your discord email is not verified, please try registering through a different method or verify your email in discord", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'dark',
          autoClose: 2000
        });
			} else if(error === 'discordNotAllowed') {
        toast("We are unable to log you in via discord, please try another method and connect your discord account once you're logged in.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'dark',
          autoClose: 2000
        });
      } else {
        toast(`Something went wrong, please try again later: ${error}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: 'dark',
          autoClose: 2000
        });
      }
    } else {
      if(userId && refreshToken) {
        dispatch(relogUser({ userId, refreshToken })).unwrap().then((res) => {
          if(res === 'Invalid token') {
          } else {
            localStorage.setItem('userInfo', JSON.stringify({ id: userId, refreshToken: res.refreshToken }));
						registerUserToken(dispatch);
            navigate('/?loggedIn=true');
          }
        })
      }
    }
  }, [searchParams, dispatch, navigate]);
  
  return (
    <div>
      <div className='max-w-[360px] w-[90%] mx-auto rounded-2xl flex flex-col justify-center min-h-screen'>
        <Helmet>
          <title>Login | Logo Diffusion</title>
        </Helmet>

				{MAINTANANCE_MODE && (
					<div className={"self-center rounded-lg bg-notification-purple-bg p-3 flex items-center gap-2 text-notification-purple-text mt-4 border border-solid border-notification-purple-border mb-4"}>
						<InfoIcon className="[&_path]:!fill-notification-purple-text w-6 h-6 shrink-0"/>
						<div className="flex-grow font-bold text-sm font-roboto">
							Maintenance mode:
							{MAINTANANCE_MESSAGE || "Logo Diffusion is currently not available due to a Bot Attack, We’re currently working on a solution, and we’ll back as soon as we fix this issue."}
						</div>
					</div>
				)}

				{view === 'main' ? (
						<div className="bg-app-bg-gray text-white text-center rounded-2xl">
							<div className="p-4 flex flex-col gap-4">
								<Logo className="self-center mt-4 mb-2"/>
								<h1 className="text-xl font-semibold text-center mb-2"> Login to Logo Diffusion </h1>
								<div className="flex flex-col gap-2">
									{!MAINTANANCE_MODE && (
										<>
											<a
												onClick={() => setLastMethod('google')}
												href={`${environment('REACT_APP_AUTH_URL') || 'https://auth.logodiffusion.com'}/auth/google-redirect?action=login`}
												className="relative bg-azure-radiance text-white font-bold w-full rounded-lg p-3 text-sm flex items-center justify-center gap-[10px]"
											>
												<GoogleIcon className="w-[16px]" /> Continue with Google
												{lastMethod === 'google' && (
													<div
														className="flex flex-col gap-3 bg-style-method-enabled border-select-border border-solid border px-2 py-1 absolute transition-all duration-300 rounded-md right-full -translate-x-2 w-28"
													>
														<div
															className="h-3 w-3 inline-block left-full absolute"
														>
															<div className="w-full h-full bg-style-method-enabled translate-y-1/2 -translate-x-1/2 rotate-45 border-select-border border-solid border-0 border-t border-r"></div>
														</div>
														<span className="text-base font-normal">
															Last Used
														</span>
													</div>
												)}
											</a>

											<a
												onClick={() => setLastMethod('discord')}
												href={`https://discord.com/api/oauth2/authorize?client_id=1098709758518763580&redirect_uri=${environment('REACT_APP_AUTH_URL') || 'https://auth.logodiffusion.com'}%2Fauth%2Flogin-discord&response_type=code&scope=identify%20email&prompt=none`}
												className="relative bg-discord-purple text-white font-bold w-full rounded-lg p-3 text-sm flex items-center justify-center gap-[10px]"
											>
												<DiscordIcon className="w-[16px]" /> Continue with Discord
												{lastMethod === 'discord' && (
													<div
														className="flex flex-col gap-3 bg-style-method-enabled border-select-border border-solid border px-2 py-1 absolute transition-all duration-300 rounded-md right-full -translate-x-2 w-28"
													>
														<div
															className="h-3 w-3 inline-block left-full absolute"
														>
															<div className="w-full h-full bg-style-method-enabled translate-y-1/2 -translate-x-1/2 rotate-45 border-select-border border-solid border-0 border-t border-r"></div>
														</div>
														<span className="text-base font-normal">
															Last Used
														</span>
													</div>
												)}
											</a>
										</>
									)}
									<button
										onClick={() => {
											setView('email')
										}}
										className="relative bg-white bg-opacity-[0.15] text-white w-full font-bold rounded-lg p-3 text-sm flex items-center justify-center gap-[10px] "
									>
										Continue with Email
										{lastMethod === 'email' && (
											<div
												className="flex flex-col gap-3 bg-style-method-enabled border-select-border border-solid border px-2 py-1 absolute transition-all duration-300 rounded-md right-full -translate-x-2 w-28"
											>
												<div
													className="h-3 w-3 inline-block left-full absolute"
												>
													<div className="w-full h-full bg-style-method-enabled translate-y-1/2 -translate-x-1/2 rotate-45 border-select-border border-solid border-0 border-t border-r"></div>
												</div>
												<span className="text-base font-normal">
													Last Used
												</span>
											</div>
										)}
									</button>
								</div>
							</div>
							<div className="bg-app-search-gray rounded-b-2xl text-sm p-4">
								Don't have an account?{" "}
								<Link to="/register" className="text-blue-600">
									Register
								</Link>
							</div>
						</div>
					) : ( 
						<div className='bg-app-bg-gray text-white text-center rounded-2xl'>
							<div className='p-4'>
								<Logo className="self-center mt-4 mb-6 mx-auto"/>
								<h1 className="text-xl font-semibold text-center mb-4"> Login to Logo Diffusion </h1>

								<form onSubmit={handleLoginUser} className='flex flex-col'>
									<input
										type="email"
										placeholder='Your email'
										className='bg-app-search-gray mb-3 p-2 rounded-md'
										value={email}
										onChange={e => setEmail(e.target.value)}
									/>

									<input
										type="password"
										placeholder='Your password'
										className='bg-app-search-gray mb-3 p-2 rounded-md'
										value={password}
										onChange={e => setPassword(e.target.value)}
									/>

									<button
												className="bg-blue-600 text-white font-bold w-full rounded-lg p-3 mb-2 text-sm disabled:bg-blue-600/20 disabled:cursor-not-allowed"
										type="submit"
										disabled={MAINTANANCE_MODE}
									>
									{isLoading ? 'Logging you in...' : 'Login' }
								</button>
								</form>

								<Link to="/reset-password" className='text-blue-600 text-center mb-2 inline-block text-sm'>Forgot password?</Link>
							</div>

							<div className='bg-app-search-gray rounded-b-2xl text-sm p-4'>
								Don't have an account? <Link to="/register" className='text-blue-600'>Register</Link>
							</div>
						</div>
        )}

      </div>

      <ToastContainer />
    </div>
  );
}

export default Login;
